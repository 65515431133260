import { Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DenunciaDetailModel } from 'app/sections/denuncies/models/denuncia-detail.model';
import { EstatOperacioModel } from 'app/sections/denuncies/models/estatoperacio.model';
import { EstatOperacionsService } from 'path-denuncies-services/estatOperacions.service';

@Component({
  selector: 'app-estat-operacio',
  templateUrl: './estat-operacio.component.html',
  providers: [EstatOperacionsService],
})
export class EstatOperacioComponent implements OnInit, OnChanges {
  @Input() data: DenunciaDetailModel;
  displayedColumns: string[] = ['operacio', 'data'];
  dataSource: MatTableDataSource<EstatOperacioModel>;

  constructor(
    private estatOperacioService: EstatOperacionsService
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] && this.data && this.data.expedient) {
      this.ObtenirOperacions();
    }
  }

  ObtenirOperacions() {
    this.estatOperacioService.getEstatOperacioDetails(this.data.expedient).subscribe(x => {
      this.dataSource = new MatTableDataSource(x);
    });
  }
}
