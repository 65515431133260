<mat-table [dataSource]="dataSource" [displayedColumns]="displayedColumns" aria-label="Elements">
  <!-- Operació Column -->
  <ng-container matColumnDef="operacio">
    <mat-header-cell *matHeaderCellDef i18n>Operació</mat-header-cell>
    <mat-cell *matCellDef="let row">{{ row.operacio }}</mat-cell>
  </ng-container>

  <!-- Data Column -->
  <ng-container matColumnDef="data">
    <mat-header-cell *matHeaderCellDef i18n>Data</mat-header-cell>
    <mat-cell *matCellDef="let row">{{ row.data | date:'shortDate' }}</mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
